<template>
  <div>
    <NavTitle class="mb16" title="企业简称审核">
      <template #right>
        <div class="input-row">
          <el-input class="input" placeholder="请输入关键词" clearable v-model="searchWord" @change="handleSearch"></el-input>
          <el-button slot="append" type="primary" icon="el-icon-search"  @click="handleSearch"></el-button>
        </div>
      </template>
    </NavTitle>
    <div class="ml20 mr20">
      <el-menu :default-active="listType" mode="horizontal" active-text-color="#08C8BD"
      @select="menuSelect">
        <el-menu-item :index="item.type" v-for="(item, index) in menuList" :key="index">
          {{item.name}}
        </el-menu-item>
      </el-menu>
    </div>
    <div class="pl20 pr20 mt16">
      <el-table
        :data="auditList"
        style="width: 100%">
        <el-table-column
          prop="mid"
          label="ID">
        </el-table-column>
        <el-table-column
          prop="name"
          label="企业名称">
        </el-table-column>
        <el-table-column
          label="原简称"
          prop="originShortName">
        </el-table-column>
        <el-table-column
          label="修改简称"
          prop="modifyShortName">
        </el-table-column>
        <el-table-column
          label="提交时间">
          <template slot-scope="scope">
            <span v-if="scope.row.auditResult">{{scope.row.auditResult.gmtCreate}}</span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="listType != 0"
          label="审核时间">
          <template slot-scope="scope">
            <span v-if="scope.row.auditResult">{{scope.row.auditResult.auditTime}}</span>
          </template>
        </el-table-column>
        <el-table-column 
          v-if="listType != 0" 
          label="审核员">
          <template slot-scope="scope">
            <span v-if="scope.row.auditResult">{{scope.row.auditResult.auditor || ''}}</span>
          </template>
        </el-table-column>
        <el-table-column 
          v-if="listType == 2" 
          label="驳回原因">
          <template slot-scope="scope">
            <span>{{scope.row.auditResult.auditReason || ''}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="230" v-if="listType == 0">
          <template slot-scope="scope">
            <div v-if="scope.row.auditStatus == 0">
              <span class="red-text pointer" @click="$refs.refusePopupRef.open({mid: scope.row.mid})">驳回</span>
              <span class="theme-text pointer ml16" @click="handlePass(scope.row.mid)">通过</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="flex justify-center mt24" v-if="auditList.length > 0">
        <Pagination :current-page="curPage + 1" @next-click="nextPage" @prev-click="prevPage"/>
      </div>
    </div>
    <RefusePopup ref="refusePopupRef" @update="getAuditList"></RefusePopup>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import LoadImage from '@/components/LoadImage'
import Pagination from '@/components/Pagination'
import RefusePopup from './components/RefusePopup.vue'
import { enterpriseAuditShortNamesApi, auditEnterpriseShortNameApi } from '@/api/admin.js'
export default {
  name: 'ShortNameList',
  components: {
    NavTitle,
    LoadImage,
    Pagination,
    RefusePopup
  },
  data() {
    return {
      menuList: [{name: '待审核', type: '0'}, {name: '已通过', type: '1'}, {name: '已驳回', type: '2'}],
      listType: '0',
      auditList: [],
      curPage: 0,
      searchWord: ''
    }
  },
  created() {
    this.getAuditList()
  },
  methods: {
    menuSelect(val) {
      this.listType = val
      this.curPage = 0
      this.getAuditList()
    },
    getAuditList() {
      enterpriseAuditShortNamesApi({
        keyword: this.searchWord,
        listType: Number(this.listType),
        startPage: this.curPage
      }).then(res => {
        const list = res.data.auditAuthList || []
        if(list.length != 0 || this.curPage == 0) {
          this.auditList = list
        } else {
          this.$tips({message: '没有更多数据了', type:'warning'})
          this.curPage--
        }
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    handleSearch() {
      this.curPage = 0
      this.getAuditList()
    },
    handlePass(mid) {
      this.$confirm('确定审核通过吗').then(action => {
        if(action == 'confirm') {
          const formData = {
            mid,
            act: 1
          }
          console.log('formdata', formData)
          auditEnterpriseShortNameApi(formData).then(res => {
            console.log('审核通过', res)
            this.$tips({message: res.msg, type:'success'})
            this.getAuditList()
          }).catch(err => {
            this.$tips({message: err, type:'error'})
          })
        }
      }).catch(err => {})
    },
    nextPage() {
      this.curPage++
      this.getAuditList()
    },
    prevPage() {
      this.curPage = this.curPage > 0 ? this.curPage - 1 : 0
      this.getAuditList()
    },
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  overflow: hidden;
}
.input-row {
  width: 380px;
  display: flex;
  align-items: center;
}
</style>